import { useUserCoupons } from "repositories/hooks";
import { selectDailyBonusCoupons } from "../utils/coupon";

export const useDailyBonusCoupon = () => {
  const { validCoupons, isLoading } = useUserCoupons();

  const dailyBonusCoupons = selectDailyBonusCoupons(validCoupons);

  return {
    dailyBonusCoupons,
    isLoading,
  };
};
