import { PageTitle } from "shared/components/molecules/PageTitle";
import { Coupon } from "features/coupon-histories/components/Coupon";
import { css } from "styled-components";
import { Campaign } from "shared/types";

interface Props {
  campaign: Campaign;
}

export const ReceiveTelFortuneCoupon = ({ campaign }: Props) => {
  const discountAmount = campaign?.coupon?.discountAmount || 2000;
  const isUserSignedUp = campaign?.condition === "user_signed_up";

  const couponCatchCopy = isUserSignedUp
    ? `はじめての電話占いで利用できる${discountAmount}円分無料クーポンがプレゼントされました！`
    : `電話占いで利用できる${discountAmount}円分無料クーポンがプレゼントされました！`;

  const couponName = isUserSignedUp
    ? `電話占いが初回無料！？電話占い${discountAmount}円分無料クーポン`
    : `電話占いが1回無料！？電話占い${discountAmount}円分無料クーポン`;

  const couponDescription = isUserSignedUp
    ? "電話鑑定をご利用された事がないお客様限定で、0円~電話鑑定をお楽しみいただけるクーポンを配布させていただきました。"
    : "一部のお客様限定で、0円~電話鑑定をお楽しみいただけるクーポンを配布させていただきました。";

  const couponExpiry =
    campaign?.coupon?.expiredAt?.format("YYYY/MM/DD HH:mm") ||
    "ログイン後にご確認ください";

  return (
    <>
      <PageTitle
        title="クーポンが付与されました🎉"
        description={couponCatchCopy}
      />
      <div tw="relative">
        <Coupon
          name={couponName}
          description={couponDescription}
          expiry={couponExpiry}
          price={discountAmount}
          css={css({
            transform: "rotate(3deg)",
            "> div": {
              backgroundImage: "linear-gradient(45deg, #CC67BA, #F41BBB)",
            },
          })}
          tw="text-left"
        />
        <Coupon
          name={couponName}
          description={couponDescription}
          expiry={couponExpiry}
          price={discountAmount}
          css={css({
            transform: "rotate(-2deg)",
            "> div": {
              backgroundImage: "linear-gradient(45deg, #E68AB4, #FE4FD0)",
            },
          })}
          tw="absolute top-0 w-full text-left"
        />
      </div>
      <p tw="text-secondary text-[9px] leading-3 mt-8 text-left">
        ※MIRORの電話鑑定で特に満足度の高い、運営が厳選した人気占い師限定でご利用いただけるクーポンになります。
        <br />
        ※クーポンをご利用いただくことで電話鑑定が0円〜ご利用いただけますので、この機会にぜひお試しください。
      </p>
    </>
  );
};
