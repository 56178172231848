import { FC } from "react";
import {
  BaseModal,
  BaseModalProps,
} from "shared/components/organisms/modal/BaseModal";

import { SettingLineNotify } from "shared/components/organisms/SettingLineNotify";

export const LineNotifyModal: FC<Omit<BaseModalProps, "title" | "subTitle">> = (
  baseModalProps
) => {
  return (
    <BaseModal {...baseModalProps}>
      <SettingLineNotify
        to="user"
        tw="p-0"
        theme="plain"
        title={
          <>
            <h3 tw="font-base text-base tracking-[.8px]">
              LINE通知設定のお願い
            </h3>
            <p tw="font-base font-light text-2xs leading-5 mt-4">
              先生からのメッセージを見逃さないように
              <br />
              LINE通知を設定しよう
            </p>
          </>
        }
      />
    </BaseModal>
  );
};
