import tw from "twin.macro";
import { Skeleton } from "./Skeleton";

interface Props {
  lineCount?: number;
  className?: string;
}

export const TextSkeleton = ({ lineCount = 4, className }: Props) => {
  return (
    <div className={className} tw="reflective after:reflection">
      {[...Array(lineCount)].map((_, index) => (
        <Skeleton
          key={index}
          tw="w-full h-3 rounded"
          css={index > 0 && tw`mt-2`}
        />
      ))}
    </div>
  );
};
