import { useLineNotify } from "../../hooks/useLineNotify";
import { useRouter } from "next/router";
import React from "react";
import { Button } from "../atoms/Button";
import { ExternalLink } from "../atoms/ExternalLink";
import { Image } from "../atoms/Image";
import tw from "twin.macro";

const themeCss = {
  default: {
    backgroundColor: tw`bg-blossom`,
    fontColor: tw`text-theme`,
    buttonColor: tw`bg-gradient-theme-to-l text-white`,
  },
  plain: {
    backgroundColor: tw`bg-white`,
    fontColor: tw`text-theme`,
    buttonColor: tw`bg-gradient-theme-to-l text-white`,
  },
  line: {
    backgroundColor: tw`bg-[#E3FFEF]`,
    fontColor: tw`text-line`,
    buttonColor: tw`bg-line text-white`,
  },
};

interface Props {
  to: "user" | "fortuneTeller";
  className?: string;
  theme?: "default" | "plain" | "line";
  title?: React.ReactNode;
}

export const SettingLineNotify = ({
  to,
  className,
  theme: themeKey = "default",
  title,
}: Props) => {
  const { push } = useRouter();

  const { client } = useLineNotify();
  const authUrl = client.code.getUri();

  const handleClickButton = () => push(authUrl);
  const theme = themeCss[themeKey];
  return (
    <div
      tw="py-6 px-8 flex-center flex-col"
      css={theme.backgroundColor}
      className={className}
    >
      {title ? (
        title
      ) : (
        <p
          tw="font-base text-[15px] leading-5 tracking-[.75px] text-center"
          css={theme.fontColor}
        >
          {to === "user" ? "占い師さん" : "お客様"}からの連絡を逃さないために
          <br />
          LINE通知設定をお願いします
        </p>
      )}
      <div tw="mt-5">
        <Image
          src={`${process.env.NEXT_PUBLIC_CDN_V2}/modal/line_notify/sample.png`}
          width={254}
          height={222}
          layout="fixed"
          priority
        />
      </div>
      <Button
        tw="w-full mt-5"
        css={theme.buttonColor}
        onClick={handleClickButton}
      >
        LINE通知を設定する
      </Button>
      <span tw="font-base text-2xs leading-[22px] mt-4">
        通知設定に関して詳しくは
        <ExternalLink
          name={
            to === "user"
              ? "howToSettingLineNotifyForUser"
              : "howToSettingLineNotifyForFortuneTeller"
          }
          css={theme.fontColor}
        >
          こちら
        </ExternalLink>
      </span>
    </div>
  );
};
