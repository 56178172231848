import { TextSkeleton } from "shared/components/atoms/TextSkeleton";
import { numberToCurrency } from "shared/utils/numberToCurrency";
import styled from "styled-components";
import tw from "twin.macro";

const LeftGrid = styled.div`
  padding: 16px 14px 16px 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 73%;
  position: relative;

  &:before {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background: #fff;
    position: absolute;
    top: 44%;
    left: -4.4%;
  }
`;

const RightGrid = styled.div`
  padding: 16px 10px 16px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 25%;
  position: relative;

  &:before {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background: #fff;
    position: absolute;
    top: 44%;
    right: -22%;
  }
`;

interface Props {
  name: string;
  description: string;
  expiry: string;
  price: number;
  disabled?: boolean;
  className?: string;
}

export const Coupon = ({
  name,
  description,
  expiry,
  price,
  disabled,
  className,
}: Props) => {
  return (
    <div className={className}>
      <div
        tw="w-full rounded-lg flex"
        css={
          disabled
            ? tw`bg-theme bg-opacity-60 rounded-bl-none rounded-br-none`
            : tw`bg-gradient-theme-to-l`
        }
      >
        <LeftGrid
          tw="border-r border-dashed border-white"
          css={disabled ? tw`pb-2` : tw`pb-4`}
        >
          <p tw="font-base text-xs leading-[18px] text-white">
            {name || <TextSkeleton lineCount={2} />}
          </p>
          {description && (
            <div tw="bg-blossom rounded-sm text-[8px] text-theme leading-3 mt-2 p-1">
              {description || <TextSkeleton lineCount={2} />}
            </div>
          )}
          <p tw="font-normal text-[9px] text-white mt-2">使用期限: {expiry}</p>
        </LeftGrid>
        <RightGrid>
          <p tw="font-base text-[12px] leading-4 text-white whitespace-nowrap">
            {numberToCurrency(price)}
          </p>
        </RightGrid>
      </div>
      {disabled && (
        <div tw="font-base text-2xs bg-secondary text-white px-4 py-2 rounded rounded-tl-none rounded-tr-none">
          電話鑑定終了時に消費されます
        </div>
      )}
    </div>
  );
};
