import { useUserCoupons } from "repositories/hooks";
import { selectAvailableCoupons } from "../utils/coupon";
import { FortuneTeller } from "shared/types";
import { useActiveCampaign } from "features/campaigns/hooks/useActiveCampaign";

export const useCampaignCoupon = () => {
  const { validCoupons, isLoading } = useUserCoupons();
  const { campaign } = useActiveCampaign();

  const campaignCoupons = validCoupons.filter(
    (coupon) => coupon.couponId === campaign?.coupon?.id
  );

  const selectAvailableCouponsFor = (fortuneTeller: FortuneTeller) => {
    return selectAvailableCoupons(campaignCoupons, fortuneTeller);
  };

  return {
    campaignCoupons,
    selectAvailableCouponsFor,
    isLoading,
  };
};
