import ClientOAuth2 from "client-oauth2";

const LineNofifyAuthInfo = {
  clientId: process.env.NEXT_PUBLIC_LINE_NOTIFY_CLIENT_ID,
  redirectUri: process.env.NEXT_PUBLIC_LINE_NOTIFY_REDIRECT_URI,
  accessTokenUri: "https://notify-bot.line.me/oauth/token",
  authorizationUri: "https://notify-bot.line.me/oauth/authorize",
  scopes: ["notify"],
  query: {
    response_type: "code",
    scope: "notify",
    state: "state",
  },
};

export class LineNotifyClient extends ClientOAuth2 {
  constructor(options?: any) {
    super({ ...LineNofifyAuthInfo, ...options });
  }
}
