import { useModal } from "mui-modal-provider";
import { ShowFnOutput } from "mui-modal-provider/dist/types";
import { useRef } from "react";
import {
  BaseModal,
  BaseModalProps,
} from "shared/components/organisms/modal/BaseModal";
import { ReceiveTelFortuneCoupon } from "../components/ReceiveTelFortuneCoupon";
import { Button } from "@miror-ui/components";
import { useRouter } from "next/router";
import { Sentry } from "lib/sentry";
import { UserCoupon } from "shared/models";
import { useActiveCampaign } from "../hooks/useActiveCampaign";

interface Props extends BaseModalProps {
  coupon: UserCoupon;
}

const ReceiveTelFortuneCouponModal = ({ ...baseModalProps }: Props) => {
  const { campaign } = useActiveCampaign();
  const { push } = useRouter();

  const handleClick = async () => {
    try {
      await push("/campaigns/present_tel_fortune_coupon");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <BaseModal backgroundOnly {...baseModalProps}>
      <div tw="bg-white pb-10 px-5 rounded-3xl text-center">
        <ReceiveTelFortuneCoupon campaign={campaign} />
        <Button
          tw="mt-[22px] from-[#FE4FD0] to-[#FE4FD0]"
          onClick={handleClick}
        >
          対象の人気占い師をチェック
        </Button>
      </div>
    </BaseModal>
  );
};

export const useReceiveTelFortuneCouponModal = () => {
  const { showModal } = useModal();
  const modal = useRef<ShowFnOutput<BaseModalProps>>();

  const showReceiveCampaignCouponModal = (options?: Props) => {
    modal.current = showModal(ReceiveTelFortuneCouponModal, {
      ...options,
      onDismiss: () => {
        modal.current?.destroy();
        options?.onDismiss?.();
      },
    });
    return modal.current;
  };

  return {
    showReceiveCampaignCouponModal,
  };
};
