import Loader from "react-spinners/ClipLoader";
import { CommonProps } from "react-spinners/interfaces";
import tw from "twin.macro";
import { css } from "styled-components";

import { Colors, zIndex } from "../../styles";

export const Loading = ({
  loading,
  className,
  ...rest
}: { className?: string } & Omit<CommonProps, "css">) => {
  return (
    <div
      tw="fixed inset-0 flex-center bg-[rgba(0, 0, 0, 0.3)] max-w-screen-sm mx-auto"
      css={[loading ? tw`flex` : tw`hidden`, css({ zIndex: zIndex.loading })]}
      className={className}
    >
      <Loader
        color={Colors.pink["01"]}
        speedMultiplier={0.8}
        loading={loading}
        {...rest}
      />
    </div>
  );
};
