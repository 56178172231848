import Link from "next/link";
import {
  BaseModal,
  BaseModalProps,
} from "shared/components/organisms/modal/BaseModal";
import { Paths } from "utils/routes";

export const RequestResolutionOfPaymentProblemModal = (
  props: BaseModalProps
) => {
  return (
    <BaseModal
      title="未払いの鑑定があります"
      subTitle="現在、決済エラーにより一部の鑑定料金が未払いとなっております。下記から入金をお願いいたします。"
      {...props}
    >
      <Link href={Paths.purchaseHistories.index()} passHref>
        <a tw="block h-14 flex-center rounded-full bg-gradient-theme-to-l font-semibold text-base tracking-067 text-white">
          未払い料金の入金に進む
        </a>
      </Link>
    </BaseModal>
  );
};
