import { LineNotifyModal } from "components/organisms/modal/LineNotifyModal";
import { useReviewModal } from "features/shared/hooks/useReviewModal";
import { useModal } from "mui-modal-provider";
import { GetStaticProps } from "next";
import { NextSeo, NextSeoProps } from "next-seo";
import { useMe } from "repositories/hooks";
import { useUnreviewOrders } from "repositories/hooks/useUnreviewOrders";
import { MetaTag } from "utils/seo";
import getNextSeoProps from "utils/ssr/getNextSeoProps";
import { useReceiveTelFortuneCouponModal } from "features/campaigns/utils/useReceiveTelFortuneCouponModal";
import { useSequence } from "shared/hooks/useSequence";
import { useCampaignCoupon } from "features/shared/hooks/useCampaignCoupon";
import dynamic from "next/dynamic";
import { useThanksOrderCoupon } from "features/shared/hooks/useThanksOrderCoupon";
import { useThanksOrderCouponModal } from "features/shared/hooks/useThanksOrderCouponModal";
import { useSession } from "next-auth/client";
import { useDailyBonusCoupon } from "features/shared/hooks/useDailyBonusCoupon";
import { useDailyBonusCouponModal } from "features/shared/hooks/useDailyBonusCouponModal";
import { RequestResolutionOfPaymentProblemModal } from "features/shared/components/RequestResolutionOfPaymentProblemModal";

const Presenter = dynamic(
  async () => (await import("features/Home/components/Home")).Home
);

const PresenterV2 = dynamic(
  async () => (await import("features/Home/components/v2/Home")).HomeV2
);

const ViewHomeTracker = dynamic(
  async () =>
    (await import("shared/lib/criteo/components/ViewHomeTracker"))
      .ViewHomeTracker
);

interface Props {
  nextSeoProps: NextSeoProps;
}

const Home = ({ nextSeoProps }: Props) => {
  const [session] = useSession();
  const { me, isLoading: isLoadingMe } = useMe();
  const { unreviewOrders, mutate } = useUnreviewOrders();
  const { thanksOrderCoupons, isLoading: isLoadingThanksOrderCoupon } =
    useThanksOrderCoupon();
  const { campaignCoupons, isLoading: isLoadingCampaignCoupon } =
    useCampaignCoupon();
  const { dailyBonusCoupons, isLoading: isLoadingDailyBonusCoupon } =
    useDailyBonusCoupon();

  const { showModal } = useModal();
  const { showReviewModal } = useReviewModal();
  const { showThanksOrderCouponModal } = useThanksOrderCouponModal();
  const { showReceiveCampaignCouponModal } = useReceiveTelFortuneCouponModal();
  const { showDailyBonusCouponModal } = useDailyBonusCouponModal();

  const { execNextSequence, resetSequenceFrom } = useSequence(
    [
      () => {
        if (isLoadingMe) {
          resetSequenceFrom(0);
        } else if (me && me.restrictedTelFortune) {
          const modal = showModal(RequestResolutionOfPaymentProblemModal, {
            onDismiss: () => {
              modal.destroy();
              execNextSequence();
            },
          });
        } else {
          execNextSequence();
        }
      },
      () => {
        if (isLoadingThanksOrderCoupon) {
          resetSequenceFrom(1);
        } else if (thanksOrderCoupons[0]) {
          showThanksOrderCouponModal({ onDismiss: execNextSequence });
        } else {
          execNextSequence();
        }
      },
      () => {
        if (isLoadingCampaignCoupon) {
          resetSequenceFrom(2);
        } else if (campaignCoupons[0]) {
          showReceiveCampaignCouponModal({
            coupon: campaignCoupons[0],
            onDismiss: execNextSequence,
          });
        } else {
          execNextSequence();
        }
      },
      () => {
        if (isLoadingDailyBonusCoupon) {
          resetSequenceFrom(3);
        } else if (dailyBonusCoupons[0]) {
          showDailyBonusCouponModal({ onDismiss: execNextSequence });
        } else {
          execNextSequence();
        }
      },
      () => {
        if (isLoadingMe) {
          resetSequenceFrom(4);
        } else if (me && !me.setLineNotify) {
          const modal = showModal(LineNotifyModal, {
            onDismiss: () => {
              modal.destroy();
              execNextSequence();
            },
          });
        } else {
          execNextSequence();
        }
      },
      () => {
        if (unreviewOrders.length > 0) {
          showReviewModal(unreviewOrders[0], mutate);
        }
      },
    ],
    { initialDelay: 500 }
  );

  return (
    <>
      <NextSeo {...nextSeoProps} />
      {session ? <PresenterV2 /> : <Presenter />}
      {!isLoadingMe && <ViewHomeTracker email={me?.email} />}
    </>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const nextSeoProps = getNextSeoProps(ctx, {
    title: MetaTag.index.title(),
    description: MetaTag.index.description(),
    keywords: MetaTag.index.keywords(),
  });

  return {
    props: {
      nextSeoProps,
    },
  };
};

export default Home;
