import HttpClient from "shared/repositories/HttpClient";
import { GetCampaignListResponse } from "shared/types";
import useSWR from "swr";

const fetcher = async (url: string) =>
  await HttpClient.miror
    .get<GetCampaignListResponse>(url)
    .then((res) => res.data);

export const useActiveCampaign = () => {
  const { data } = useSWR(`/campaigns/search?limit=1`, fetcher);

  return { campaign: data?.campaigns[0] };
};
