import { TextareaHTMLAttributes, ChangeEvent, forwardRef } from "react";
import { useController, useFormContext } from "react-hook-form";
import tw from "twin.macro";

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ name, onChange: onChangeProp, disabled, ...rest }, ref) => {
    const { control } = useFormContext();
    const {
      field: { onChange, ...field },
      fieldState,
    } = useController({ control, name });

    const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e);
      if (onChangeProp) onChangeProp(e);
    };

    return (
      <>
        <textarea
          id={name}
          tw="bg-gray w-full p-4 rounded-[9px] border-none font-base font-normal text-xs focus:outline-none"
          css={[
            fieldState.error && tw`border border-solid border-orange`,
            disabled && tw`text-secondary cursor-not-allowed`,
          ]}
          onChange={handleChangeText}
          disabled={disabled}
          {...field}
          {...rest}
          ref={ref}
        />
        {fieldState.error && (
          <span tw="font-light text-[8px] leading-[14px] text-orange mt-2 w-full">
            {fieldState.error.message}
          </span>
        )}
      </>
    );
  }
);

TextArea.displayName = "TextArea";
