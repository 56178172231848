const formatter = Intl.NumberFormat("ja-JP", {
  style: "currency",
  currency: "JPY",
});

/**
 *
 * @param number
 * @returns
 */
export const numberToCurrency = (number: number): string => {
  const result = !number ? formatter.format(0) : formatter.format(number);
  // Intl.NumberFormatが実行される場所がbrowserかnodeランタイムかによって返ってくる値が異なり、
  // 「X did not match.Server: y Client: z 」のwarningが発生するため置換処理を噛ませる
  return result.replace("￥", "¥");
};
